body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.toolTipContainer {
  max-width:320px;
}

@media screen and (min-width: 600px) {

  .mobileOnly {
    display:none !important;
  }
}

@media screen and (max-width: 1200px) {

  .accountSettingsTabs a {
    min-width:48px;
  }
  .MuiTabs-root {
    overflow:visible !important;
  }
}

.infinite-scroll-component {
  
}

#divScroller1 {
  
}

#divScroller {
  height: calc(100% - 150px);
}

.contentWidth {
  max-width: 1280px;
  width:100%;
}

ol {
  padding-inline-start: 10px;
}

ol li {
  margin-top:10px;
}