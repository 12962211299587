.dialogTitle {
  padding: 10px 10px 10px 10px;
}

.dialogHeader {
  width:100%;
  display:flex;
  justify-content:space-between;
  align-items:center;
  height:50px
}

.imageEditor {
  max-width: 1280px;
  width:100%;
  margin:auto;
}

.hide {
  visibility: hidden;
}
