.App {
  min-width:280px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.appBarHeaderBorder {
  background: linear-gradient(90deg, rgba(0,150,255,1) 17%, rgba(255,127,1,1) 27%);
}

@media screen and (max-width: 600px) {
  #headerLogo {
    display:none;
  }
}

.App-header {
  background-color: red;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #0096ff;
}

.App-link {
  color: #61dafb;
}

.MuiToolbar-root {
  min-height:56px !important;
}

.MuiAutocomplete-listbox {
  max-height:150px;
}

.permalink
{
    /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;

  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;

  /*Used only for colored theme*/
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;

  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;

  /* Used when no type is provided
   toast("**hello**")*/
  --toastify-color-progress-light: linear-gradient(90deg, rgba(0,150,255,1) 17%, rgba(255,127,1,1) 27%);

  /* Used when no type is provided*/
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: linear-gradient(90deg, rgba(0,150,255,1) 17%, rgba(255,127,1,1) 27%);
  --toastify-color-progress-success: linear-gradient(90deg, rgba(0,150,255,1) 17%, rgba(255,127,1,1) 27%);
  --toastify-color-progress-warning: linear-gradient(90deg, rgba(0,150,255,1) 17%, rgba(255,127,1,1) 27%);
  --toastify-color-progress-error: linear-gradient(90deg, rgba(0,150,255,1) 17%, rgba(255,127,1,1) 27%);
}
