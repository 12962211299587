.Mui-expanded {
  margin:0px;
}

#manualCodeExpander {
  min-height: 48px;
  background-color: red !important;
}

.accordianText p{
  font-size:14px !important;
}
#manualCodeExpander p{
  
}